import { EquipmentRelatedDocument } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const documentEquipmentRelatedStateInitialState = {
    contractIds: [] as string[],
    start: undefined as Date | undefined,
    end: undefined as Date | undefined,
    documents: [] as EquipmentRelatedDocument[],
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
} as const;

type DocumentEquipmentRelatedState = typeof documentEquipmentRelatedStateInitialState;

export const DocumentEquipmentRelatedReducer: Reducer<DocumentEquipmentRelatedState> = (state = documentEquipmentRelatedStateInitialState, action) => {
    switch (action.type) {

        case '@CUSTOMER_SERVICE/DOCUMENT/EQUIPMENT_RELATED/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                start: action.payload.start,
                end: action.payload.end,
                documents: action.payload.documents,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/EQUIPMENT_RELATED/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/EQUIPMENT_RELATED/SUBMIT_SUCCESS':
            return {
                ...state,
                ...documentEquipmentRelatedStateInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/EQUIPMENT_RELATED/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;

    }
};
import { SubscribeCode } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { ApiRequest, Reducer } from 'store';

export const initialState = {
    subscribeCode: {} as ApiRequest<SubscribeCode>,
} as const;

export type SubscribeCodeState = typeof initialState;

export const SubscribeCodeReducer: Reducer<SubscribeCodeState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SUBSCRIBE_CODE/GET_CODE':
            return {
                ...state,
                subscribeCode: {
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@SUBSCRIBE_CODE/GET_CODE_SUCCESS':
            return {
                ...state,
                subscribeCode: {
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.data,
                },
            };

        case '@SUBSCRIBE_CODE/GET_CODE_FAILURE':
            return {
                ...state,
                subscribeCode: {
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};

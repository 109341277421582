import { Amount, ContractAssets, ContractBilling, ContractInformation, ContractSortingSource, ContractsStatus, SearchContractRequest, SortDirection } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_CONTRACTS_TAKE = 10;

export const DEFAULT_SEARCH_CONTRACTS_FILTERS = {
    skip: 0,
    take: DEFAULT_SEARCH_CONTRACTS_TAKE,
    sortDirection: SortDirection.Desc,
    sortSource: ContractSortingSource.BusinessNumber,
};

export const initialState = {
    search: {
        filters: {
            ...DEFAULT_SEARCH_CONTRACTS_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]> & {
        filters: SearchContractRequest,
        total: number,
    },
    contracts: {
        information: {} as { [id: string]: ApiRequest<ContractInformation> },
        assets: {} as { [id: string]: ApiRequest<ContractAssets> },
        billing: {} as { [id: string]: ApiRequest<ContractBilling> },
    },
    statuses: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<ContractsStatus[]>,
    ids: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]>,
    services: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<Amount>,
} as const;

export type ContractsState = typeof initialState;

export const ContractsReducer: Reducer<ContractsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@CONTRACTS/SET_SEARCH_CONTRACTS_SEARCH_TERMS':
            if (state.search.filters.businessNumber === action.payload.businessNumber) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        businessNumber: action.payload.businessNumber,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_CHANGE_PAGE':
            if (state.search.filters.skip === action.payload.skip
                && state.search.filters.take === action.payload.take
            ) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_SORT':
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        sortDirection: action.payload.sortDirection,
                        sortSource: action.payload.sortSource,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_FINANCIAL_TYPE':
            if (state.search.filters.financialType === action.payload.financialType) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        financialType: action.payload.financialType,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_STATUS_TYPE':
            if (state.search.filters.status === action.payload.status) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        status: action.payload.status,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_END_DATE_MIN':
            if (state.search.filters.endDateMin === action.payload.endDateMin) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        endDateMin: action.payload.endDateMin,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_END_DATE_MAX':
            if (state.search.filters.endDateMax === action.payload.endDateMax) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        endDateMax: action.payload.endDateMax,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/RESET_SEARCH_CONTRACTS':
            const isAlreadyDefaultFilter = Object.entries(state.search.filters).every(([filterKey, filterValue]) => {
                switch (filterKey) {
                    case 'skip':
                        return filterValue === 0;
                    case 'take':
                        return filterValue === DEFAULT_SEARCH_CONTRACTS_TAKE;
                    case 'sortDirection':
                        return filterValue === SortDirection.Desc;
                    case 'sortSource':
                        return filterValue === ContractSortingSource.BusinessNumber;
                    default:
                        return !filterValue;
                }
            });
            if (isAlreadyDefaultFilter) {
                return state;
            }

            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...DEFAULT_SEARCH_CONTRACTS_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/SEARCH_CONTRACTS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@CONTRACTS/SEARCH_CONTRACTS_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.contractIds,
                    total: action.payload.total,
                },
            };

        case '@CONTRACTS/SEARCH_CONTRACTS_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/GET_CONTRACT_INFORMATION':
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    information: {
                        ...state.contracts.information,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: true,
                        },
                    },
                },
            };

        case '@CONTRACTS/GET_CONTRACT_INFORMATION_SUCCESS':
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    information: {
                        ...state.contracts.information,
                        [action.payload.contractId]: {
                            didInvalidate: false,
                            isFetching: false,
                        },
                    },
                },
            };

        case '@CONTRACTS/GET_CONTRACT_INFORMATION_FAILURE':
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    information: {
                        ...state.contracts.information,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: false,
                        },
                    },
                },
            };

        case '@CONTRACTS/GET_CONTRACT_ASSETS':
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    assets: {
                        ...state.contracts.assets,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: true,
                        },
                    },
                },
            };

        case '@CONTRACTS/GET_CONTRACT_ASSETS_SUCCESS':
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    assets: {
                        ...state.contracts.assets,
                        [action.payload.contractId]: {
                            didInvalidate: false,
                            isFetching: false,
                        },
                    },
                },
            };

        case '@CONTRACTS/GET_CONTRACT_ASSETS_FAILURE':
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    assets: {
                        ...state.contracts.assets,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: false,
                        },
                    },
                },
            };

        case '@CONTRACTS/GET_AMOUNT_TO_BE_REGULARIZED':
            return {
                ...state,
                services: {
                    ...state.services,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@CONTRACTS/GET_AMOUNT_TO_BE_REGULARIZED_SUCCESS':
            return {
                ...state,
                services: {
                    data: action.payload.amountToBeRegularized,
                    didInvalidate: false,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/GET_AMOUNT_TO_BE_REGULARIZED_FAILURE':
            return {
                ...state,
                services: {
                    ...state.services,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/GET_CONTRACT_BILLING':
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    billing: {
                        ...state.contracts.billing,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: true,
                        },
                    },
                },
            };

        case '@CONTRACTS/GET_CONTRACT_BILLING_SUCCESS':
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    billing: {
                        ...state.contracts.billing,
                        [action.payload.contractId]: {
                            didInvalidate: false,
                            isFetching: false,
                        },
                    },
                },
            };

        case '@CONTRACTS/GET_CONTRACT_BILLING_FAILURE':
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    billing: {
                        ...state.contracts.billing,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: false,
                        },
                    },
                },
            };

        case '@CONTRACTS/FETCH_CONTRACTS_STATUSES':
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@CONTRACTS/FETCH_CONTRACTS_STATUSES_SUCCESS':
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.statuses,
                },
            };

        case '@CONTRACTS/FETCH_CONTRACTS_STATUSES_FAILURE':
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CONTRACTS/FETCH_CONTRACTS_IDS':
            return {
                ...state,
                ids: {
                    ...state.ids,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@CONTRACTS/FETCH_CONTRACTS_IDS_SUCCESS':
            return {
                ...state,
                ids: {
                    ...state.ids,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.ids,
                },
            };

        case '@CONTRACTS/FETCH_CONTRACTS_IDS_FAILURE':
            return {
                ...state,
                ids: {
                    ...state.ids,
                    didInvalidate: true,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

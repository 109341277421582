import { ApiRequest, Reducer } from 'store';
import { FileAndType } from 'services/FileService';
import { FinancialRequestCount, FinancialRequestDetailed, FinancialRequestSummary, FinancialSortingSource, SortDirection } from 'services/ApiService/Franfinance/FranfinanceApiClient';

export const DEFAULT_SEARCH_FINANCIAL_REQUEST_TAKE = 10;

export const DEFAULT_SEARCH_FINANCIAL_REQUEST_FILTERS = {
    skip: 0,
    take: DEFAULT_SEARCH_FINANCIAL_REQUEST_TAKE,
    sortDirection: SortDirection.Desc,
    sortSource: FinancialSortingSource.InternalReferenceId,
};

export interface SearchFinancialRequest {
    skip: number;
    take: number;
    sortDirection?: SortDirection,
    sortSource?: FinancialSortingSource,
}

export const initialState = {
    financialRequest: {
        isFetching: undefined as boolean | undefined,
    } as ApiRequest,
    count: {} as ApiRequest<FinancialRequestCount>,
    files: [] as FileAndType[],
    validatedFinancialRequest: {} as { [id: number]: ApiRequest<FinancialRequestDetailed> },
    search: {
        filters: {
            ...DEFAULT_SEARCH_FINANCIAL_REQUEST_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<FinancialRequestSummary[]> & {
        filters: SearchFinancialRequest,
        total: number,
    },
} as const;

export type FinancialState = typeof initialState;

export const FinancialReducer: Reducer<FinancialState> = (state = initialState, action) => {
    switch (action.type) {
        case '@FINANCIAL/SUBMIT':
            return {
                ...state,
                financialRequest: {
                    isFetching: true,
                },
            };

        case '@FINANCIAL/SUBMIT_SUCCESS':
            return {
                ...state,
                financialRequest: {
                    isFetching: false,
                },
            };

        case '@FINANCIAL/SUBMIT_FAILURE':
            return {
                ...state,
                financialRequest: {
                    isFetching: false,
                },
            };

        case '@FINANCIAL/SET_FILES':
            return {
                ...state,
                files: action.payload.files,
            };

        case '@FINANCIAL/CLEAR_FILES':
            return {
                ...state,
                files: [],
            };

        case '@FINANCIAL/GET_REQUEST_BY_ID':
            return {
                ...state,
                validatedFinancialRequest: {
                    ...state.validatedFinancialRequest,
                    [action.payload.requestId]: {
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@FINANCIAL/GET_REQUEST_BY_ID_SUCCESS':
            return {
                ...state,
                validatedFinancialRequest: {
                    ...state.validatedFinancialRequest,
                    [action.payload.requestId]: {
                        didInvalidate: false,
                        isFetching: false,
                        data: action.payload.data,
                    },
                },
            };

        case '@FINANCIAL/GET_REQUEST_BY_ID_FAILURE':
            return {
                ...state,
                validatedFinancialRequest: {
                    ...state.validatedFinancialRequest,
                    [action.payload.requestId]: {
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };
        case '@FINANCIAL/SEARCH_FINANCIAL_REQUEST':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@FINANCIAL/SEARCH_FINANCIAL_REQUEST_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.FinancialRequestsList,
                    total: action.payload.total,
                },
            };

        case '@FINANCIAL/SEARCH_FINANCIAL_REQUEST_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@FINANCIAL/SET_SEARCH_FINANCIAL_REQUEST_CHANGE_PAGE':
            if (state.search.filters.skip === action.payload.skip && state.search.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };
        case '@FINANCIAL/GET_COUNT':
            return {
                ...state,
                count: {
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@FINANCIAL/GET_COUNT_SUCCESS':
            return {
                ...state,
                count: {
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.data,
                },
            };

        case '@FINANCIAL/GET_COUNT_FAILURE':
            return {
                ...state,
                count: {
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@FINANCIAL/SET_SEARCH_FINANCIAL_SORT':
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        sortDirection: action.payload.sortDirection,
                        sortSource: action.payload.sortSource,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        default:
            return state;
    }
};

import { CopyOfContractualElementDocument } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const documentContractualCopyInitialState = {
    contractIds: [] as string[],
    documents: [] as CopyOfContractualElementDocument[],
    reason: undefined as string | undefined,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
} as const;

type DocumentContractualCopyState = typeof documentContractualCopyInitialState;

export const DocumentContractualCopyReducer: Reducer<DocumentContractualCopyState> = (state = documentContractualCopyInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/DOCUMENT/CONTRACTUAL_COPY/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                documents: action.payload.documents,
                reason: action.payload.reason,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/CONTRACTUAL_COPY/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/CONTRACTUAL_COPY/SUBMIT_SUCCESS':
            return {
                ...state,
                ...documentContractualCopyInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/CONTRACTUAL_COPY/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

export type PagerLinkType = 'first' | 'last' | '<' | '>' | '...' | 'siblingPage' | 'currentPage';

export type PaginationLink = {
    type: PagerLinkType;
    page: number;
}

export const generatePaginationLinks = (page: number, totalPages: number, nbSiblings: number): PaginationLink[] => {

    if (totalPages <= 1) {
        return [];
    }

    const links: PaginationLink[] = [];

    links.push({ type: '<', page: page - 1 });

    if (page !== 1)
        links.push({ type: 'first', page: 1 });

    links.push(...generateSiblingPageLinks(page, totalPages, nbSiblings));

    if (page !== totalPages)
        links.push({ type: 'last', page: totalPages });

    links.push({ type: '>', page: page + 1 });

    return links;
};

const generateSiblingPageLinks = (page: number, totalPages: number, nbSiblings: number): PaginationLink[] => {
    const links: PaginationLink[] = [];

    const maxNextSiblings = Math.min(totalPages - page, nbSiblings);
    const rightOffset = nbSiblings - maxNextSiblings;

    const firstGroupPage: number = Math.max(1, page - nbSiblings - rightOffset);
    const lastGroupPage: number = Math.min(totalPages, firstGroupPage + 2 * nbSiblings);

    if (firstGroupPage > 1) {
        links.push({ type: '...', page: firstGroupPage - 1 });
    }

    for (let i = firstGroupPage; i < page; i++) {
        if (i !== 1)
            links.push({ type: 'siblingPage', page: i });
    }

    links.push({ type: 'currentPage', page: page });

    for (let i = page + 1; i <= lastGroupPage; i++) {
        if (i !== totalPages)
            links.push({ type: 'siblingPage', page: i });
    }

    if (lastGroupPage < totalPages - 1) {
        links.push({ type: '...', page: lastGroupPage + 1 });
    }

    return links;
};

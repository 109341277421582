declare global {
    // tslint:disable-next-line:interface-name
    interface Element {
        trackUser(): void;
        trackPageView(href?: string, title?: string): void;
        trackEvent(category: string, action: string, name?: string, value?: string): void;
        pushInstruction(name: string, ...args: any[]): void;
    }
}

const getAnalytics = () => {
    return new Promise<Element | null>((resolve) => {
        const webAnalytics = document.querySelector('sgwt-web-analytics');
        if (webAnalytics) {
            resolve(webAnalytics);
        }
        const getwebAnalytics = () => {
            document.removeEventListener('sgwt-web-analytics--ready', getwebAnalytics);
            resolve(document.querySelector('sgwt-web-analytics'));
        };
        document.addEventListener('sgwt-web-analytics--ready', getwebAnalytics);
    });
};

export const trackPageView = async (href?: string, title?: string) => {
    const webAnalytics = await getAnalytics();
    webAnalytics?.trackPageView(href, title);
};

export const trackEvent = async (category: string, action: string, name?: string, value?: string) => {
    const webAnalytics = await getAnalytics();
    webAnalytics?.trackEvent(category, action, name, value);
};

export const pushInstruction = async (name: string, ...args: any[]) => {
    const webAnalytics = await getAnalytics();
    webAnalytics?.pushInstruction(name, args);
};

export const trackUser = async () => {
    const webAnalytics = await getAnalytics();
    webAnalytics?.trackUser();
};

import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const documentAccountExtractInitialState = {
    contractIds: [] as string[],
    start: undefined as Date | undefined,
    end: undefined as Date | undefined,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
} as const;

type DocumentAccountExtractState = typeof documentAccountExtractInitialState;

export const DocumentAccountExtractReducer: Reducer<DocumentAccountExtractState> = (state = documentAccountExtractInitialState, action) => {
    switch (action.type) {

        case '@CUSTOMER_SERVICE/DOCUMENT/ACCOUNT_EXTRACT/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                start: action.payload.start,
                end: action.payload.end,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/ACCOUNT_EXTRACT/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/ACCOUNT_EXTRACT/SUBMIT_SUCCESS':
            return {
                ...state,
                ...documentAccountExtractInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/ACCOUNT_EXTRACT/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;

    }
};
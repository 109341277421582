import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const changeBankDetailsInitialState = {
    contractIds: [] as string[],
    accountHolder: '' as string,
    bic: '' as string,
    iban: '' as string,
    ribFile: undefined as File | undefined,
    termsAcceptedProfessionalAccount: false as boolean,
    termsAcceptedDebitAuthorization: false as boolean,
    termsAcceptedCompanyIsTheOnlyTenant: false as boolean,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

type ChangeBankDetailsState = typeof changeBankDetailsInitialState;

export const ChangeBankDetailsReducer: Reducer<ChangeBankDetailsState> = (state = changeBankDetailsInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/CHANGE/BANK_DETAILS/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                accountHolder: action.payload.accountHolder,
                bic: action.payload.bic,
                iban: action.payload.iban,
                termsAcceptedProfessionalAccount: action.payload.termsAcceptedProfessionalAccount,
                termsAcceptedDebitAuthorization: action.payload.termsAcceptedDebitAuthorization,
                termsAcceptedCompanyIsTheOnlyTenant: action.payload.termsAcceptedCompanyIsTheOnlyTenant,
                ribFile: action.payload.ribFile,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/CHANGE/BANK_DETAILS/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },

            };
        case '@CUSTOMER_SERVICE/CHANGE/BANK_DETAILS/SUBMIT_SUCCESS':
            return {
                ...state,
                ...changeBankDetailsInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/CHANGE/BANK_DETAILS/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

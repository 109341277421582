export type Reducer<S = any, A = any> = (
    state: S | undefined,
    action: A
) => S

type ReducersMapObject<S = any, A = any> = {
    [K in keyof S]: Reducer<S[K], A>
}

type StateFromReducersMapObject<M, A> = M extends ReducersMapObject
    ? { [P in keyof M]: M[P] extends Reducer<infer S, A> ? S : never }
    : never

declare const $CombinedState: unique symbol;
interface EmptyObject {
    readonly [$CombinedState]?: undefined
}
type CombinedState<S> = EmptyObject & S

export function combineReducers<S, A extends { type: string, payload?: any }>(reducers: ReducersMapObject<S, any>): Reducer<CombinedState<S>, A> {
    const reducerKeys = Object.keys(reducers);
    const finalReducers: ReducersMapObject = {};
    for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        if (typeof (reducers as ReducersMapObject)[key] === 'function') {
            finalReducers[key] = (reducers as ReducersMapObject)[key];
        }
    }
    const finalReducerKeys = Object.keys(finalReducers);

    return function combination(state: StateFromReducersMapObject<ReducersMapObject, A> = {}, action: A) {
        let hasStateChanged = false;
        const nextState: StateFromReducersMapObject<ReducersMapObject, A> = {};
        for (let i = 0; i < finalReducerKeys.length; i++) {
            const key = finalReducerKeys[i];
            const reducer = finalReducers[key];
            const previousStateForKey = state[key];
            const nextStateForKey = reducer(previousStateForKey, action);
            if (typeof nextStateForKey === 'undefined') {
                const actionType = action && action.type;
                throw new Error(
                    `When called with an action of type ${actionType ? `"${String(actionType)}"` : '(unknown type)'
                    }, the slice reducer for key "${key}" returned undefined. ` +
                    'To ignore an action, you must explicitly return the previous state. ' +
                    'If you want this reducer to hold no value, you can return null instead of undefined.'
                );
            }
            nextState[key] = nextStateForKey;
            hasStateChanged = hasStateChanged || nextStateForKey !== previousStateForKey;
        }
        hasStateChanged = hasStateChanged || finalReducerKeys.length !== Object.keys(state).length;
        return (hasStateChanged ? nextState : state) as CombinedState<S>;
    };
}

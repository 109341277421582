import { Address } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { Reducer } from 'store/configureStore';
import { FileAndType } from 'services/FileService';
import { ApiRequest } from 'store/sharedModels';

export const changeHeadOfficeAddressInitialState = {
    contractIds: [] as string[],
    headOfficeAddress: {
        streetNumber: '',
        streetName: '',
        zipCode: undefined as number | undefined,
        city: '',
    } as Address,
    kbisFile: undefined as File | undefined,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
} as const;

type ChangeHeadOfficeAddressState = typeof changeHeadOfficeAddressInitialState;

export const ChangeHeadOfficeAddressReducer: Reducer<ChangeHeadOfficeAddressState> = (state = changeHeadOfficeAddressInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/CHANGE/HEAD_OFFICE_ADDRESS/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                headOfficeAddress: { ...action.payload.headOfficeAddress },
                kbisFile: action.payload.kbisFile,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/CHANGE/HEAD_OFFICE_ADDRESS/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },
            };
        case '@CUSTOMER_SERVICE/CHANGE/HEAD_OFFICE_ADDRESS/SUBMIT_SUCCESS':
            return {
                ...state,
                ...changeHeadOfficeAddressInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/CHANGE/HEAD_OFFICE_ADDRESS/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

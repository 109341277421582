import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractReturnOfEquipmentInitialState = {
    contractIds: [] as string[],
    lastName: undefined as string | undefined,
    firstName: undefined as string | undefined,
    email: undefined as string | undefined,
    phoneNumber: undefined as string | undefined,
    jobTitle: undefined as string | undefined,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

type EndOfContractReturnOfEquipmentState = typeof endOfContractReturnOfEquipmentInitialState;

export const EndOfContractReturnOfEquipmentReducer: Reducer<EndOfContractReturnOfEquipmentState> = (state = endOfContractReturnOfEquipmentInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/RETURN_OF_EQUIPMENT/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                lastName: action.payload.lastName,
                firstName: action.payload.firstName,
                email: action.payload.email,
                phoneNumber: action.payload.phoneNumber,
                jobTitle: action.payload.jobTitle,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/RETURN_OF_EQUIPMENT/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/RETURN_OF_EQUIPMENT/SUBMIT_SUCCESS':
            return {
                ...state,
                ...endOfContractReturnOfEquipmentInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/RETURN_OF_EQUIPMENT/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

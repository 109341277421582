import React, { useEffect } from 'react';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';
import { useAppContext } from 'components/common/AppProvider';
import { FranfinanceSelectors } from 'store/Normalizr/Selectors';
import { trackPageView, trackUser } from 'services/SgwtWebAnalytics';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-mini-footer': {
                'id': string;
                'no-border': boolean;
                mode: string;
                'contact-us-by-help-center': string;
                'accessibility-compliance': string;
            };
        }
    }
}

export const Footer: React.FC = () => {
    const {
        state: {
            appContext: {
                loggedUserId,
            },
            entities,
        },
    } = useAppContext();
    const loggedUser = FranfinanceSelectors.getLoggedUser(loggedUserId, entities.franFinance);

    useEffect(() => {
        (async () => {
            if (loggedUser) {
                trackUser();
                trackPageView(window.location.href, window.document.title);
            }
        })();
    }, [loggedUserId]);

    return (
        <div className="footer w-100 d-flex border-top bg-lvl1">
            <div className="flex-grow-1">
                <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-mini-footer/v4/sgwt-mini-footer.js`}>
                    <sgwt-mini-footer
                        id="sgm-mini-footer"
                        no-border
                        mode="b2b2c"
                        contact-us-by-help-center="#sgwtSuperHelpCenter"
                        legal-notices={`{"en":[{"label":"Données Personnelles", "value":"${configuration.externalLinks.franfinanceLegalNotice}"},
                        {"label":"Mentions Légales", "value":"${configuration.externalLinks.franfinanceMentionsLegales}"},
                        {"label":"Cookies", "value":"${configuration.externalLinks.franfinanceCookies}"}],
                        "fr":[{"label":"Données Personnelles", "value":"${configuration.externalLinks.franfinanceLegalNotice}"},
                        {"label":"Mentions Légales", "value":"${configuration.externalLinks.franfinanceMentionsLegales}"},
                        {"label":"Cookies", "value":"${configuration.externalLinks.franfinanceCookies}"}]}`}
                        accessibility-compliance="none"
                    />
                </WidgetLazyLoader>
            </div>
        </div>
    );
};

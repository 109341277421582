import { ApiRequest, Reducer } from 'store';
import { ISendReponseNonComplianceStepForm } from 'components/Pages/CustomerRequestDetails/Steps/SendReponseNonCompliance';
import { ISendReponseInsctructionStepForm } from 'components/Pages/CustomerRequestDetails/Steps/SendReponseInstruction';

export const initialState = {
    casesNonCompliance: {} as { [caseId: string]: ISendReponseNonComplianceStepForm },
    submitNonCompliance: {} as { [caseId: string]: ApiRequest },
    caseInstruction: {} as { [caseId: string]: ISendReponseInsctructionStepForm },
    submitInstruction: {} as { [caseId: string]: ApiRequest },
} as const;

export type CustomerServiceDetailsResponseState = typeof initialState;

export const CustomerServiceDetailsResponseReducer: Reducer<CustomerServiceDetailsResponseState> = (state = initialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SET_FORM_VALUE':
            return {
                ...state,
                casesNonCompliance: {
                    ...state.casesNonCompliance,
                    [action.payload.caseId]: {
                        ...state.casesNonCompliance[action.payload.caseId],
                        files: action.payload.files,
                        commentary: action.payload.commentary,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SUBMIT':
            return {
                ...state,
                submitNonCompliance: {
                    ...state.submitNonCompliance,
                    [action.payload.caseId]: {
                        isFetching: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SUBMIT_SUCCESS':
            return {
                ...state,
                submitNonCompliance: {
                    ...state.submitNonCompliance,
                    [action.payload.caseId]: {
                        isFetching: false,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SUBMIT_FAILURE':
            return {
                ...state,
                submitNonCompliance: {
                    ...state.submitNonCompliance,
                    [action.payload.caseId]: {
                        isFetching: false,
                    },
                },
            };

        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SET_FORM_VALUE':
            return {
                ...state,
                caseInstruction: {
                    ...state.caseInstruction,
                    [action.payload.caseId]: {
                        ...state.caseInstruction[action.payload.caseId],
                        files: action.payload.files,
                        commentary: action.payload.commentary,
                        customerDecision: action.payload.customerDecision,
                        paymentMethod: action.payload.paymentMethod,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SUBMIT':
            return {
                ...state,
                submitInstruction: {
                    ...state.submitInstruction,
                    [action.payload.caseId]: {
                        isFetching: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SUBMIT_SUCCESS':
            return {
                ...state,
                submitInstruction: {
                    ...state.submitInstruction,
                    [action.payload.caseId]: {
                        isFetching: false,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SUBMIT_FAILURE':
            return {
                ...state,
                submitInstruction: {
                    ...state.submitInstruction,
                    [action.payload.caseId]: {
                        isFetching: false,
                    },
                },
            };
        
        
        default:
            return state;
    }
};
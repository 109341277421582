import { TypeOfBuyback } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractEarlyRedemptionInitialState = {
    contractIds: [] as string[],
    typeofbuyback: undefined as TypeOfBuyback | undefined,
    materials: undefined as string[] | undefined,
    redemptionDate: undefined as Date | undefined,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

type EndOfContractEarlyRedemptionState = typeof endOfContractEarlyRedemptionInitialState;

export const EndOfContractEarlyRedemptionReducer: Reducer<EndOfContractEarlyRedemptionState> = (state = endOfContractEarlyRedemptionInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EARLY_REDEMPTION/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                typeofbuyback: action.payload.typeofbuyback,
                redemptionDate: action.payload.redemptionDate,
                files: action.payload.files,
                materials: action.payload.materials,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EARLY_REDEMPTION/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EARLY_REDEMPTION/SUBMIT_SUCCESS':
            return {
                ...state,
                ...endOfContractEarlyRedemptionInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EARLY_REDEMPTION/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

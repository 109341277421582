import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShepherdTourContext, ShepherdOptionsWithType, Tour, ShepherdTour } from 'react-shepherd';
import { RoutePaths } from 'routes/RoutePaths';

import '@sg-bootstrap/shepherd/dist/theme-standard.min.css';

type PopperPlacement = NonNullable<ShepherdOptionsWithType['attachTo']>['on'];

interface TourStepItem {
    id?: string;
    selector: string;
    on?: PopperPlacement;
    scrollTo?: boolean | ScrollIntoViewOptions;
    title?: string | (() => string);
    text?: string | ReadonlyArray<string> | HTMLElement | (() => string | ReadonlyArray<string> | HTMLElement);
}

export const ContextualTourProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const tourOptions: Tour.TourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
        },
        useModalOverlay: true,
        exitOnEsc: true,
    };

    const getButtons = (index: number, stepsCount: number) => {
        const buttons = [];
        if (index > 0) {
            buttons.push({
                text: 'Previous',
                type: 'back',
                secondary: true,
            });
        }
        if (index === (stepsCount - 1)) {
            buttons.push({
                text: 'Close',
                type: 'hide',
            });
        } else {
            buttons.push({
                text: 'Next',
                type: 'next',
            });
        }
        return buttons;
    };

    const items: TourStepItem[] = [
        {
            id: 'highlights',
            selector: '#highlights-home',
            on: 'bottom',
            scrollTo: {
                behavior: 'smooth',
                block: 'center',
            },
            title: 'Highlights',
            text: 'Our latest thematic pieces and digital content will be regularly updated and highlighted here',
        },
        {
            id: 'latest-reports',
            selector: '#customize-latest-reports',
            on: 'top',
            scrollTo: {
                behavior: 'smooth',
                block: 'center',
            },
            title: 'Customize this content',
            text: `Are you interested in a specific asset class?</br />
                   Customize your view and menu tabs to only see the topics you need the most`,
        },
        {
            id: 'most-viewed',
            selector: '#most-viewed-home',
            on: 'left',
            scrollTo: {
                behavior: 'smooth',
                block: 'start',
            },
            title: 'Most Viewed',
            text: 'Find out what\'s trending and which publications your peers are reading the most',
        },
        {
            id: 'recommended-publications',
            selector: '#recommended-publications-home',
            on: 'right',
            scrollTo: true,
            title: 'Recommended Publications',
            text: 'Our newly improved algorithm will display reports specifically tailored to your interests based on your activity over the past 3 months',
        },
    ];

    const elementIsLoaded = (selector: string, resolve: (value: void | PromiseLike<void>) => void) => {
        const itemLoaded = document.querySelector(selector);
        if (itemLoaded) {
            resolve();
            return;
        }

        setTimeout(() => {
            elementIsLoaded(selector, resolve);
        }, 100);
    };

    const steps = (): ShepherdOptionsWithType[] => {
        return items.map((item: TourStepItem, index: number) => (
            {
                id: item.id,
                attachTo: {
                    element: item.selector,
                    on: item.on || 'bottom',
                },
                beforeShowPromise: () => {
                    return new Promise<void>((resolve) => {
                        elementIsLoaded(item.selector, resolve);
                    });
                },
                buttons: getButtons(index, items.length),
                title: item.title,
                text: item.text,
                scrollTo: item.scrollTo || true,
                when: {
                    show() {
                        const currentStepElement = (this as any).el;
                        const footer = currentStepElement.querySelector('.shepherd-footer');
                        if (footer) {
                            const progress = document.createElement('span');
                            progress.classList.add('shepherd-progress');
                            progress.innerHTML = `${index + 1} of ${items.length}`;
                            footer.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
                        }
                    },
                },
            }
        ));
    };

    return <ShepherdTour steps={steps()} tourOptions={tourOptions}>
        {children}
    </ShepherdTour>;
};

export const ContextualTour: React.FC = () => {
    const tour = useContext(ShepherdTourContext);
    const navigate = useNavigate();
    const { search } = useLocation();

    const startTour = () => {
        if (window.location.pathname == '/') {
            tour?.start();
        } else {
            navigate(RoutePaths.Home.url('true'));
        }
    };

    const getHelpCenter = () => {
        return new Promise<Element | null>((resolve) => {
            const helpCenter = document.querySelector('sgwt-help-center');
            if (helpCenter) {
                resolve(helpCenter);
            }
            document.addEventListener('sgwt-help-center--ready', () => {
                resolve(document.querySelector('sgwt-help-center'));
            });
        });
    };

    useEffect(() => {
        (async () => {
            const helpCenter = await getHelpCenter();
            helpCenter?.addEventListener('sgwt-help-center--start-introduction-tour', startTour);
        })();

        return () => {
            const helpCenter = document.querySelector('sgwt-help-center');
            helpCenter?.removeEventListener('sgwt-help-center--start-introduction-tour', startTour);
        };
    }, []);

    useEffect(() => {
        if (search.includes('startGuideTour=true')) {
            tour?.start();
        }
        else {
            tour?.cancel();
        }
    }, [search]);

    return null;
};

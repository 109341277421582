import { ApiRequest, Reducer } from 'store';
import { CustomerRequestsCount } from 'services/ApiService/Franfinance/FranfinanceApiClient';

export const initialState = {
    customerRequestsByContractId: {} as { [id: string]: ApiRequest<string[]> },
    customerRequests: {} as ApiRequest<string[]>,
    count: {} as { [id: string]: ApiRequest<CustomerRequestsCount> },
    globalCount: {} as ApiRequest<CustomerRequestsCount>,
} as const;

export type CustomerRequestsState = typeof initialState;

export const CustomerRequestsReducer: Reducer<CustomerRequestsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_REQUESTS/GET_REQUEST_BY_CONTRACT_ID':
            return {
                ...state,
                customerRequestsByContractId: {
                    ...state.customerRequestsByContractId,
                    [action.payload.contractId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@CUSTOMER_REQUESTS/GET_REQUEST_BY_CONTRACT_ID_SUCCESS':
            return {
                ...state,
                customerRequestsByContractId: {
                    ...state.customerRequestsByContractId,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.data,
                    },
                },
            };

        case '@CUSTOMER_REQUESTS/GET_REQUEST_BY_CONTRACT_ID_FAILURE':
            return {
                ...state,
                customerRequestsByContractId: {
                    ...state.customerRequestsByContractId,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@CUSTOMER_REQUESTS/GET_REQUEST':
            return {
                ...state,
                customerRequests: {
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@CUSTOMER_REQUESTS/GET_REQUEST_SUCCESS':
            return {
                ...state,
                customerRequests: {
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.data,
                },
            };

        case '@CUSTOMER_REQUESTS/GET_REQUEST_FAILURE':
            return {
                ...state,
                customerRequests: {
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@CUSTOMER_REQUESTS/GET_COUNT':
            return {
                ...state,
                globalCount: {
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@CUSTOMER_REQUESTS/GET_COUNT_SUCCESS':
            return {
                ...state,
                globalCount: {
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.data,
                },
            };

        case '@CUSTOMER_REQUESTS/GET_COUNT_FAILURE':
            return {
                ...state,
                globalCount: {
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@CUSTOMER_REQUESTS/GET_COUNT_BY_ID':
            return {
                ...state,
                count: {
                    ...state.count,
                    [action.payload.contractId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@CUSTOMER_REQUESTS/GET_COUNT_BY_ID_SUCCESS':
            return {
                ...state,
                count: {
                    ...state.count,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.data,
                    },
                },
            };

        case '@CUSTOMER_REQUESTS/GET_COUNT_BY_ID_FAILURE':
            return {
                ...state,
                count: {
                    ...state.count,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@CUSTOMER_REQUESTS/INVALIDATE_LIST_BY_ID':
            return {
                ...state,
                customerRequestsByContractId: {
                    ...state.customerRequestsByContractId,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
                count: {
                    ...state.count,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@CUSTOMER_REQUESTS/INVALIDATE_LIST':
            return {
                ...state,
                customerRequests: {
                    isFetching: false,
                    didInvalidate: true,
                },
                globalCount: {
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};

import { FinancialConditionPeriodicity, FinancialConditionType } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const changeFinancialConditionInitialState = {
    contractIds: [] as string[],
    effectiveDate: undefined as Date | undefined,
    effectiveDay: undefined as string | undefined,
    financialConditionType: FinancialConditionType.EffectiveDate as FinancialConditionType,
    financialConditionPeriodicity: undefined as FinancialConditionPeriodicity | undefined,
    commentary: undefined as string | undefined,
    lastFinancialStatementFile: undefined as File | undefined,
    lastVatStatementsFile: undefined as File | undefined,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

type ChangeFinancialConditionState = typeof changeFinancialConditionInitialState;

export const ChangeFinancialConditionReducer: Reducer<ChangeFinancialConditionState> = (state = changeFinancialConditionInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/CHANGE/FINANCIAL_CONDITION/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                effectiveDate: action.payload.effectiveDate,
                effectiveDay: action.payload.effectiveDay,
                financialConditionType: action.payload.financialConditionType,
                financialConditionPeriodicity: action.payload.financialConditionPeriodicity,
                commentary: action.payload.commentary,
                lastFinancialStatementFile: action.payload.lastFinancialStatementFile,
                lastVatStatementsFile: action.payload.lastVatStatementsFile,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/CHANGE/FINANCIAL_CONDITION/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },

            };
        case '@CUSTOMER_SERVICE/CHANGE/FINANCIAL_CONDITION/SUBMIT_SUCCESS':
            return {
                ...state,
                ...changeFinancialConditionInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/CHANGE/FINANCIAL_CONDITION/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

import { ContractRelatedDocument } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const documentContractRelatedInitialState = {
    contractIds: [] as string[],
    documents: [] as ContractRelatedDocument[],
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,

} as const;

type DocumentContractRelatedState = typeof documentContractRelatedInitialState;

export const DocumentContractRelatedReducer: Reducer<DocumentContractRelatedState> = (state = documentContractRelatedInitialState, action) => {
    switch (action.type) {

        case '@CUSTOMER_SERVICE/DOCUMENT/CONTRACT_RELATED/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                documents: action.payload.documents,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/CONTRACT_RELATED/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/CONTRACT_RELATED/SUBMIT_SUCCESS':
            return {
                ...state,
                ...documentContractRelatedInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/DOCUMENT/CONTRACT_RELATED/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;

    }
};
import { Reducer as BaseReducer, combineReducers } from './storeHelper';
import { AppContextAction } from './AppContext/AppContextActions';
import { AppContextReducer } from './AppContext/AppContextReducer';
import { ContractsAction } from './Contracts/ContractsActions';
import { ContractsReducer } from './Contracts/ContractsReducer';
import { CustomerServiceAction } from './CustomerService/CustomerServiceActions';
import { CustomerServiceReducer } from './CustomerService/CustomerServiceReducer';
import { ModalsAction } from './Modals/ModalsActions';
import { ModalsReducer } from './Modals/ModalsReducer';
import { NormalizrAction } from './Normalizr/NormalizrAction';
import { NormalizrReducer } from './Normalizr/NormalizrReducer';
import { PersonsAction } from './Persons/PersonsActions';
import { PersonsReducer } from './Persons/PersonsReducer';
import { ReferentialAction } from './Referential/ReferentialActions';
import { ReferentialReducer } from './Referential/ReferentialReducer';
import { SimulationsAction } from './Simulations/SimulationsActions';
import { SimulationsReducer } from './Simulations/SimulationsReducer';
import { FinancialAction } from './Financial/FinancialAction';
import { FinancialReducer } from './Financial/FinancialReducer';
import { HomeReducer } from './Home/HomeReducer';
import { HomeAction } from './Home/HomeActions';
import { InvoicesReducer } from './Invoices/InvoicesReducer';
import { InvoicesActions } from './Invoices/InvoicesActions';
import { SubscribeCodeAction } from './SubscribeCode/SubscribeCodeActions';
import { SubscribeCodeReducer } from './SubscribeCode/SubscribeCodeReducer';
import { CustomerRequestsAction } from './CustomerRequests/CustomerRequestsAction';
import { CustomerRequestsReducer } from './CustomerRequests/CustomerRequestsReducer';
import { CustomerRequestDetailsAction } from './CustomerRequestDetails/CustomerRequestDetailsActions';
import { CustomerRequestDetailsReducer } from './CustomerRequestDetails/CustomerRequestDetailsReducer';
import { CustomerServiceDetailsResponseActions } from './CustomerServiceDetailsReponse/CustomerServiceDetailsReponseActions';
import { CustomerServiceDetailsResponseReducer } from './CustomerServiceDetailsReponse/CustomerServiceDetailsReponseReducer';

export type Action =
    | { type: 'TEST' }
    | HomeAction
    | AppContextAction
    | InvoicesActions
    | ContractsAction
    | CustomerServiceAction
    | ModalsAction
    | NormalizrAction
    | PersonsAction
    | ReferentialAction
    | SimulationsAction
    | SubscribeCodeAction
    | FinancialAction
    | CustomerRequestsAction
    | CustomerRequestDetailsAction
    | CustomerServiceDetailsResponseActions;


const reducers = {
    home: HomeReducer,
    appContext: AppContextReducer,
    invoices: InvoicesReducer,
    contracts: ContractsReducer,
    customerService: CustomerServiceReducer,
    entities: NormalizrReducer,
    modals: ModalsReducer,
    persons: PersonsReducer,
    referential: ReferentialReducer,
    simulations: SimulationsReducer,
    financial: FinancialReducer,
    subscribeCode: SubscribeCodeReducer,
    customerRequests: CustomerRequestsReducer,
    customerRequestDetails: CustomerRequestDetailsReducer,
    customerServiceDetailsResponse: CustomerServiceDetailsResponseReducer,
};

type StateReducersMapObject = typeof reducers;
type StateKeys = keyof StateReducersMapObject;
export type State = { [key in StateKeys]: ReturnType<StateReducersMapObject[key]> };

export const reducer = combineReducers<State, Action>(reducers);

export const initialState = reducer({} as State, {} as any);

export type Reducer<S = any> = BaseReducer<S, Action>;

import { ContractTransferReason, ThirdAddress, TypeOfBuyback } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Contact } from 'store/CustomerService/Change/ContractTransfer/ContractTransferReducer';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractContractTransferInitialState = {
    contractIds: [] as string[],
    typeofbuyback: undefined as TypeOfBuyback | undefined,
    contractTransferReason: undefined as ContractTransferReason | undefined,
    effectiveDate: undefined as Date | undefined,
    materials: undefined as string[] | undefined,
    commentary: undefined as string | undefined,
    buyerContact: {
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        phoneNumber: '',
    } as Contact,
    buyerAddress: {
        streetNumber: '',
        streetName: '',
        zipCode: undefined as number | undefined,
        city: '',
        sirenCode: '',
        businessName: '',
    } as ThirdAddress,
    kbisFile: undefined as File | undefined,
    businessTakeoverFile: undefined as File | undefined,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

export type EndOfContractContractTransferState = typeof endOfContractContractTransferInitialState;

export const EndOfContractContractTransferReducer: Reducer<EndOfContractContractTransferState> = (state = endOfContractContractTransferInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/CONTRACT_TRANSFER/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                typeofbuyback: action.payload.typeofbuyback,
                contractTransferReason: action.payload.contractTransferReason,
                effectiveDate: action.payload.effectiveDate,
                materials: action.payload.materials,
                commentary: action.payload.commentary,
                buyerContact: { ...action.payload.buyerContact },
                buyerAddress: { ...action.payload.buyerAddress },
                kbisFile: action.payload.kbisFile,
                businessTakeoverFile: action.payload.businessTakeoverFile,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/CONTRACT_TRANSFER/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/CONTRACT_TRANSFER/SUBMIT_SUCCESS':
            return {
                ...state,
                ...endOfContractContractTransferInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/CONTRACT_TRANSFER/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

import { Address } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const billingBillingAddressInitialState = {
    contractIds: [] as string[],
    billingAddress: {
        streetNumber: '',
        streetName: '',
        zipCode: undefined as number | undefined,
        city: '',
    } as Address,
    deliveryAddress: undefined as Address | undefined,
    hasDeliveryAddress: false as boolean,
    termsAccepted: false as boolean,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

type BillingBillingAddressState = typeof billingBillingAddressInitialState;

export const BillingBillingAddressReducer: Reducer<BillingBillingAddressState> = (state = billingBillingAddressInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/BILLING/BILLING_ADDRESS/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                billingAddress: { ...action.payload.billingAddress },
                deliveryAddress: action.payload.deliveryAddress !== undefined ? { ...action.payload.deliveryAddress } : undefined,
                hasDeliveryAddress: action.payload.hasDeliveryAddress,
                termsAccepted: action.payload.termsAccepted,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/BILLING/BILLING_ADDRESS/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },

            };
        case '@CUSTOMER_SERVICE/BILLING/BILLING_ADDRESS/SUBMIT_SUCCESS':
            return {
                ...state,
                ...billingBillingAddressInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/BILLING/BILLING_ADDRESS/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

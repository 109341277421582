import { ValidityPeriod } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const billingOrderNumberInitialState = {
    contractIds: [] as string[],
    orderNumber: '' as string,
    validityPeriod: ValidityPeriod.ContractDuration as ValidityPeriod,
    orderFormFile: undefined as File | undefined,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

type BillingOrderNumberState = typeof billingOrderNumberInitialState;

export const BillingOrderNumberReducer: Reducer<BillingOrderNumberState> = (state = billingOrderNumberInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/BILLING/ORDER_NUMBER/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                orderNumber: action.payload.orderNumber,
                validityPeriod: action.payload.validityPeriod,
                orderFormFile: action.payload.orderFormFile,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/BILLING/ORDER_NUMBER/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },
            };
        case '@CUSTOMER_SERVICE/BILLING/ORDER_NUMBER/SUBMIT_SUCCESS':
            return {
                ...state,
                ...billingOrderNumberInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/BILLING/ORDER_NUMBER/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};

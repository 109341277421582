import SearchIcon from '@mui/icons-material/Search';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface IQuickSearchProps {
    onSearch: (searchTerms: string) => void;
    searchTerms?: string | null;
    placeholder?: string | null;
    autofocus?: boolean;
    style?: CSSProperties;
}

export const QuickSearch: React.FC<IQuickSearchProps> = ({ onSearch, searchTerms, autofocus = true, placeholder, style }) => {
    const { t } = useTranslation();
    const searchInput = useRef<HTMLInputElement>(null);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        onSearch(event.target.value);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (autofocus) {
            const isKeyValid = event.key && event.key.match(/^[a-zA-Z0-9]{1}$/);
            // we listen only if the source is not an input
            if (event.target
                && event.target instanceof Element
                && event.target.tagName.toLocaleLowerCase() !== 'input'
                && event.target.tagName.toLocaleLowerCase() !== 'textarea'
                && isKeyValid
                && !event.altKey
                && !event.ctrlKey) {
                searchInput.current?.focus();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="input-group">
            <div className="input-icon-start text-secondary">
                <SearchIcon fontSize="small" />
            </div>
            <input
                ref={searchInput}
                style={style}
                type="search"
                className="form-control"
                placeholder={placeholder || t('common:QuickSearch.Search by') || 'Search by ...'}
                onChange={handleSearch}
                defaultValue={searchTerms || ''}
            />
        </div>
    );
};

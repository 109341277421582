import { FileAndType } from 'services/FileService';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractDenialOfContractRenewalInitialState = {
    contractIds: [] as string[],
    termsAccepted: false as boolean,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
} as const;

export type EndOfContractDenialOfContractRenewalState = typeof endOfContractDenialOfContractRenewalInitialState;

export const EndOfContractDenialOfContractRenewalReducer: Reducer<EndOfContractDenialOfContractRenewalState> = (state = endOfContractDenialOfContractRenewalInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/DENIAL_OF_CONTRACT_RENEWAL/SET_FORM_VALUE':
            return {
                ...state,
                contractIds: action.payload.contractIds,
                termsAccepted: action.payload.termsAccepted,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/DENIAL_OF_CONTRACT_RENEWAL/SUBMIT':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: true,
                },

            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/DENIAL_OF_CONTRACT_RENEWAL/SUBMIT_SUCCESS':
            return {
                ...state,
                ...endOfContractDenialOfContractRenewalInitialState,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/DENIAL_OF_CONTRACT_RENEWAL/SUBMIT_FAILURE':
            return {
                ...state,
                request: {
                    ...state.request,
                    isFetching: false,
                },
            };
        default:
            return state;
    }
};
